import React, { Component } from 'react';
import Typist from 'react-typist'
import _ from 'lodash';
import { getCommandResult } from './CommandResults';
import FloatingPanel from './FloatingPanel';

class CommandLineInterface extends Component {
  constructor() {
    super();

    this.commandInput = React.createRef();
  }

  state = {
    outputHistory: [],
    commandHistory: [],
    inputValue: '',
    isCrawler: false,
    typistEnabled: true,
  };

  componentDidMount() {
    const commandFromPath = this.props.location.pathname.substr(1);
    if (commandFromPath) {
      this.handleCommandFromPath(commandFromPath);
    } else {
      getCommandResult('about').then(result => this.setState({ outputHistory: [result] }));

      document.title = "zheludov.com";
    }
    this.commandInput.current.focus();
    document.addEventListener('click', this.inputFocus);

    const crawlers = [
      'googlebot',
      'bingbot',
      'slurp', // Yahoo
      'duckduckbot',
      'baiduspider',
      'reactsnap'
    ];

    for (let crawler of crawlers) {
      if (navigator.userAgent.toLowerCase().includes(crawler)) {
        console.log("Crawler", crawler);

        this.setState({ isCrawler: true });
        break;
      }
    }

    const typistEnabled = localStorage.getItem('typistEnabled') === 'true';
    this.setState({ typistEnabled });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const commandFromPath = this.props.location.pathname.substr(1);
      if (commandFromPath) {
        this.handleCommandFromPath(commandFromPath);
      }
    }
  }

  inputFocus = () => {
    this.commandInput.current.focus()
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const inputValue = this.state.inputValue;
      if (inputValue === 'clear') {
        this.setState({
          outputHistory: [],
          commandHistory: [...this.state.commandHistory, inputValue],
          inputValue: '',
        });

        document.title = "zheludov.com";
      }
      else {
        getCommandResult(inputValue).then(result => this.setState({
          outputHistory: [...this.state.outputHistory, { type: false, text: this.getCommandEntryLine(inputValue) }, result],
          commandHistory: [...this.state.commandHistory, inputValue],
          historyCommandIndex: undefined,
          inputValue: '',
        }));
      }

      document.title = `zheludov.com: ${inputValue}`;

      this.commandInput.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  getCommandEntryLine = text => (
    <span>
      <span class="cmd-machine-name">zheludov.com</span>
      <span className='cmd-path'>$</span>
      <span>{text}</span>
    </span>
  )

  handleKeyDown = (event) => {
    const { commandHistory, historyCommandIndex } = this.state;

    if (event.key === 'ArrowUp' && commandHistory.length > 0) {
      if (historyCommandIndex === undefined) {
        this.setState({
          historyCommandIndex: commandHistory.length - 1,
          inputValue: commandHistory[commandHistory.length - 1],
        });
      }
      else if (historyCommandIndex > 0) {
        this.setState({
          historyCommandIndex: historyCommandIndex - 1,
          inputValue: commandHistory[historyCommandIndex - 1],
        });
      }
      else {
        this.setState({
          historyCommandIndex: undefined,
          inputValue: '',
        });
      }
    }
    else if (event.key === 'ArrowDown' && commandHistory.length > 0) {
      if (historyCommandIndex === undefined) {
        this.setState({
          historyCommandIndex: 0,
          inputValue: commandHistory[0],
        });
      }
      else if (historyCommandIndex < commandHistory.length - 1) {
        this.setState({
          historyCommandIndex: historyCommandIndex + 1,
          inputValue: commandHistory[historyCommandIndex + 1],
        });
      }
      else {
        this.setState({
          historyCommandIndex: undefined,
          inputValue: '',
        });
      }
    }
  }

  handleInputValueChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  }

  handleLineTyped = () => {
    this.commandInput.current.scrollIntoView({ behavior: "smooth" });
  }

  handleCommandFromPath = (command) => {
    command = command.replace(/\//g, ' '); // replaces slashes with spaces

    document.title = `zheludov.com: ${command}`;

    getCommandResult(command).then(result => this.setState({
      outputHistory: [
        ...this.state.outputHistory,
        { type: false, text: `zheludov.com:/$ ${command}` },
        result
      ],
      commandHistory: [...this.state.commandHistory, command],
    }));
  }

  type = (index, text) => {
    if (this.state.isCrawler || !this.state.typistEnabled) {
      return text;
    }
    else {
      return (<Typist onLineTyped={this.handleLineTyped} key={`output-${index}`} stdTypingDelay={0} avgTypingDelay={0} cursor={{ show: false }}>
        {text}
      </Typist>);
    }
  }

  toggleTypist = () => {
    this.setState(prevState => {
      const newTypistEnabled = !prevState.typistEnabled;
      localStorage.setItem('typistEnabled', newTypistEnabled);
      return { typistEnabled: newTypistEnabled };
    });
  }

  render() {
    const { outputHistory, inputValue, typistEnabled } = this.state;

    console.log(this.state.isCrawler);

    return <>
      <FloatingPanel typistEnabled={typistEnabled} toggleTypist={this.toggleTypist} />
      <div>
        {outputHistory.map((output, index) => output.type ?
          this.type(index, output.text)
          : <p key={`output-${index}`}>{output.text}</p>)}


        <span className="command">
          {this.getCommandEntryLine()}
          <input ref={this.commandInput} value={inputValue} onKeyDown={this.handleKeyDown} onKeyPress={this.handleKeyPress} onChange={this.handleInputValueChange} type="text" />
        </span>
      </div>
    </>
  }
}

export default (CommandLineInterface);