import React, { Component } from "react";
import { handleBlogCommand } from './BlogCommands';
import ReactGA from 'react-ga';


function aboutText() {
  return {
    type: true,
    text: (
      <p>
        Hi, I'm Alex,
        <br />
        Welcome to my website!
        <br />I am a technologist, cloud architect and full stack developer
        focused in Azure and open source technologies.
        <br />I have built and lead high performance development teams and
        together we have built enterprise grade highly performant and scalable
        software.
        <br />
        <br />I work very hard to stay current with ever changing technology
        trends and I learn new things every day.
        <br />
        My goal is to improve and make the biggest impact to your organization!{" "}
        <br />
        <br />
        My key skills and expertise include:
        <ul class="skills">
          <li class="skills-area">
            <span>Languages</span>
            <ul>
              <li>C#, TypeScript, JavaScript, SQL, PowerShell, Bicep, CSS</li>
            </ul>
          </li>
          <li class="skills-area">
            <span>Azure Cloud</span>
            <ul>
              <li>
                Kubernetes, Functions, Cosmos DB, Event Grid, Event Hub, Service
                bus, Web jobs, App Services, Redis Cache, Azure Storage, Azure
                Search, Logic Apps, Azure Active Directory, Azure Media Services
              </li>
            </ul>
          </li>
          <li class="skills-area">
            <span>Front End Frameworks</span>
            <ul>
              <li>VueJS, ReactJS</li>
            </ul>
          </li>
          <li class="skills-area">
            <span>Databases</span>
            <ul>
              <li>Microsoft SQL, Cosmos DB, MySQL, Mongo DB</li>
            </ul>
          </li>
          <li class="skills-area">
            <span>DevOps</span>
            <ul>
              <li>Azure DevOps/VSTS, Flux CD</li>
            </ul>
          </li>
          <li class="skills-area">
            <span>Other</span>
            <ul>
              <li>
              Docker, Git, Elastic Search, .Net Core/Framework, NServiceBus, MS Orleans, 
              Entity Framework, Dapper, XUnit, MSTest, Moq, REST, SpecFlow
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <span>
          Type '<a href="/blog">blog</a>' + <kbd>Enter</kbd> -- to get a list of my blog posts.
        </span>
        <br />
        <span>
          Type '<a href="/help">help</a>' + <kbd>Enter</kbd> -- for available commands.
        </span>
      </p>
    ),
  };
}

function helpText() {
  return {
    type: true,
    text: (
      <div>
        <span>
          Type [command] + <kbd>Enter</kbd>
        </span>
        <ul>
          <li>'<a href="/about">about</a>' -- About me</li>
          <li>'<a href="/contact">contact</a>' -- My current contact information</li>
          <li>'<a href="/resume">resume</a>' -- Download my resume</li>
          <li>'<a href="/help">help</a>' -- displays this list</li>
          <li>'<a href="/blog">blog</a>' -- list of blog posts</li>
          <li>'<a href="/blog">blog</a> [name]' -- renders blog post by name</li>
        </ul>
      </div>
    ),
  };
}

function errorText() {
  return {
    type: true,
    text: (
      <p>
        <span className="error">Command not found!</span> &nbsp;&nbsp;
        <div className="inline">
          <div className="inline guy">
            (<div className="inline arm">╯</div>°□°）
            <div className="inline arm">╯</div>
          </div>
          <div className="inline" id="table">
            ┻━┻
          </div>
          <div className="inline guy"></div>
        </div>
        <br />
        <span>
          Type 'help' + <kbd>Enter</kbd> -- for available commands.
        </span>
      </p>
    ),
  };
}

function contactText() {
  return {
    type: true,
    text: (
      <p>
        email: <a href="mailto:alex@zheludov.com">alex@zheludov.com</a>
        <br />
        LinkedIn:{" "}
        <a target="_blank" href="https://www.linkedin.com/in/alex-zheludov/">
          https://www.linkedin.com/in/alex-zheludov/
        </a>
      </p>
    ),
  };
}

function resumeText() {
  return {
    type: true,
    text: (
      <div>
        <p>
          Download my resume:{" "}
          <a target="_blank" href="Alex_Zheludov_Resume.pdf">
            link
          </a>
        </p>
        <p>
          LinkedIn:{" "}
          <a target="_blank" href="https://www.linkedin.com/in/alex-zheludov/">
            https://www.linkedin.com/in/alex-zheludov/
          </a>
        </p>
      </div>
    ),
  };
}

function newLine() {
  return {
    type: false,
    text: "",
  };
}

export async function getCommandResult(command) {
  ReactGA.event({category: 'User', action: 'command', label: command });

  switch (command.toLowerCase().split(' ')[0]) {
    case "help":
      return helpText();
    case "about":
      return aboutText();
    case "resume":
      return resumeText();
    case "contact":
      return contactText();
    case "blog":
      var commandOutput = await handleBlogCommand(command);
      return commandOutput;
    case "":
      return newLine();
    default:
      return errorText();
  }
}
