import React, { useEffect } from 'react';
import './App.css';
import CommandLineWrapper from './Components/CommandLineWrapper';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-XKLZNNQJ9D');

// Check if we are in the prerendering phase (react-snap)
const isPrerendering = typeof window !== 'undefined' && window.snapSaveState;

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Log the page view with the current location
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <GoogleAnalytics />
      <Routes>
        {/* Other routes and components */}
        {isPrerendering ? (
          // Components to render during prerendering (if needed)
          <Route path="*" element={<div>Prerendering...</div>} />
        ) : (
          // Components to render during regular client-side rendering
          <>
            <Route path="*" element={<CommandLineWrapper />} />
            {/* Add other routes as needed */}
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
