import React from 'react';
import { useLocation } from 'react-router-dom';
import CommandLineInterface from './CommandLineInterface';

function CommandLineWrapper() {
    const location = useLocation();

    return <CommandLineInterface location={location} />;
}

export default CommandLineWrapper;