import React, { Component } from 'react';
import Switch from 'react-switch';

class FloatingPanel extends Component {
  state = {
    isVisible: false,
  };

  togglePanel = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible,
    }), () => {
      if (this.state.isVisible) {
        this.autoHidePanel();
      }
    });
  }

  autoHidePanel = () => {
    setTimeout(() => {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }, 10000); // Hide panel after 10 seconds
  }

  render() {
    return (
      <div>
        <div style={styles.toggleButton} onClick={this.togglePanel}>
          <ArrowIcon />
        </div>
        <div style={{ ...styles.panel, right: this.state.isVisible ? '40px' : '-250px' }}>
          <div style={styles.control}>
            <span style={styles.label}>Typist:</span>
            <Switch
              checked={this.props.typistEnabled}
              onChange={this.props.toggleTypist}
              onColor="#FFA500" // Orange color
              offColor="#CCCCCC"
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={14}
              height={18}
              width={36}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          <div style={styles.linkContainer}>
            <a href="https://www.linkedin.com/in/alex-zheludov/" target="_blank" rel="noopener noreferrer" style={styles.linkText}>
              LinkedIn
            </a>
            <a href="https://github.com/alex-zheludov" target="_blank" rel="noopener noreferrer" style={styles.linkText}>
              GitHub
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" className="bi bi-chevron-left" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  </svg>
);

const styles = {
  toggleButton: {
    position: 'fixed',
    top: '10px',
    right: '0px',
    background: '#282c34',
    border: '1px solid #444',
    borderRadius: '8px 0 0 8px',
    padding: '10px',
    cursor: 'pointer',
    zIndex: 1001,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out',
  },
  panel: {
    position: 'fixed',
    top: '10px',
    background: '#282c34',
    border: '1px solid #444',
    padding: '10px',
    zIndex: 1000,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'right 0.5s ease-in-out',
    width: '200px',
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },
  label: {
    fontFamily: '"Fira Code Nerd Font", monospace',
    fontSize: '14px',
    marginRight: '10px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  linkText: {
    color: '#FFA500',
    textDecoration: 'none',
    fontFamily: '"Fira Code Nerd Font", monospace',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px',
  }
};

export default FloatingPanel;
