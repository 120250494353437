import React from "react";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../blog.css';
import ReactGA from 'react-ga';


const lineNumbersStyle = {
    paddingRight: '1em',
    opacity: '0.7',
    // Add any other styles you wish for the line numbers here.
};

const renderers = {
    code: ({ language, value }) => {
        return <SyntaxHighlighter style={vscDarkPlus} language={language} children={value} showInlineLineNumbers={true} lineNumberStyle={lineNumbersStyle} />;
    },
    heading: (props) => {
        if (props.level === 4) {  // Check if it's an H4
            return <h4 className="h4-header">{props.children}</h4>;
        }
        // Render other headings normally
        return React.createElement(`h${props.level}`, props, props.children);
    }
}


async function fetchBlogList() {
    const CONTAINER_URL = "https://saportfoliodeveus.blob.core.windows.net/blog?restype=container&comp=list";

    try {
        const response = await fetch(CONTAINER_URL);
        const data = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");

        const blobElements = xmlDoc.getElementsByTagName("Blob");
        const blobs = [];

        for (let i = 0; i < blobElements.length; i++) {
            const blob = {};

            // Extract the blob name
            const nameElements = blobElements[i].getElementsByTagName("Name");
            if (nameElements.length > 0 && nameElements[0].childNodes.length > 0) {
                let fullName = nameElements[0].childNodes[0].nodeValue;

                let nameWithoutExtension = fullName.split('.').slice(0, -1).join('.');
                blob.name = nameWithoutExtension;
            }

            blobs.push(blob);
        }

        return blobs;

    } catch (error) {
        console.error('Failed fetching blog list:', error);
        return [];
    }
}

async function fetchBlobContent(blobName) {
    const BLOB_URL = `https://saportfoliodeveus.blob.core.windows.net/blog/${blobName}`;

    try {
        const response = await fetch(BLOB_URL);
        // Assuming the content is text, adjust if needed
        const content = await response.text();

        return content;
    } catch (error) {
        console.error('Failed fetching blob content:', error);
        return null;
    }
}

export async function handleBlogCommand(command) {
    const commandParts = command.split(' ');
    if (commandParts.length === 1) {
        // Handle 'blog' command
        const blogList = await fetchBlogList();


        var output = {
            type: true,
            text: (
                <ul>
                    {blogList.map(blog => (
                        <li key={blog.name}>
                            <a href={`blog/${blog.name}`}>{blog.name}</a>
                        </li>
                    ))}
                </ul>
            ),
        };

        return output;
    } else if (commandParts.length === 2) {
        // Handle 'blog some-title' command
        const blogFilename = commandParts[1];  // assuming the filename is 'some-title'

        var content = await fetchBlobContent(`${blogFilename}.md`)
        
        ReactGA.pageview(`/blog/${blogFilename}`);

        return {
            type: true,
            text: (
                <div>
                    <ReactMarkdown renderers={renderers} children={content} />
                    <span>
                        Type '<a href="/blog">blog</a>' + <kbd>Enter</kbd> -- to get a list of my blog posts.
                    </span>
                    <br />
                    <span>
                        Type '<a href="/help">help</a>' + <kbd>Enter</kbd> -- for available commands.
                    </span>
                </div>
            ),
        };
    } else {
        return {
            type: true,
            text: <p>Invalid blog command!</p>,
        };
    }
}